interface HorizontalArticleCardSkeletonProps {
  variant?: "sm" | "md" | "lg";
  showRegion?: boolean;
}

export default function HorizontalArticleCardSkeleton({
  variant = "lg",
  showRegion = false,
}: HorizontalArticleCardSkeletonProps) {
  return (
    <div className="flex items-start gap-4 group">
      <div
        className={
          "relative w-full aspect-[16/10] rounded-lg overflow-hidden " +
          (variant === "sm"
            ? "min-w-[33%] w-[33%]"
            : variant === "md"
            ? "min-w-[42%] w-[42%]"
            : "min-w-[42%] w-[42%]")
        }
      >
        <span className="block w-full h-full transition-all bg-slate-300 animate-pulse"></span>
      </div>
      <div className="w-full">
        <span className="block h-[16px] w-full bg-slate-300 transition-all animate-pulse"></span>
        {/* <span className="block h-[16px] w-full bg-slate-300 transition-all animate-pulse mt-2"></span> */}
        <span className="block h-[16px] w-[70%] bg-slate-300 transition-all animate-pulse mt-2"></span>
        {showRegion && (
          <span
            aria-live="polite"
            className="block h-[12px] w-[40%] bg-slate-300 transition-all animate-pulse mt-[10px]"
          ></span>
        )}
      </div>
    </div>
  );
}
