import envConfig from "@/config/envConfig";
import { QUERY_QUOTES, QUERY_QUOTES_SLUG, QUERY_TOTAL_QUOTES_COUNT } from "@/graphql/quotes";
import { QuoteDetailType, QuotesType } from "@/types/quotes";

export const fetchQuotes = async ({
  limit = 10,
  sort = "-createdAt",
  cache = "no-cache",
}: {
  limit?: number;
  sort?: "createdAt" | "-createdAt";
  cache?: RequestCache;
}) => {
  try {
    const response = await fetch(envConfig.publicQraphqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: QUERY_QUOTES,
        variables: {
          limit,
          sort,
        },
      }),
      cache,
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();
    return (respData?.data?.Quotes?.docs || []) as QuotesType[];
  } catch (err) {
    console.log(err);
  }
};

export const fetchSingleDetailQuote = async ({
  slug,
  cache = "no-cache",
  token,
}: {
  slug: string;
  cache?: RequestCache;
  token?: string;
}) => {
  try {
    const response = await fetch(envConfig.publicServerUrl + `/api/v1/quotes/slug/${slug}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      cache,
    });

    // if (!response.ok) {
    //   throw new Error(`Error: ${response.statusText}`);
    // }

    const respData = await response.json();

    return (respData?.data || null) as QuoteDetailType;
  } catch (err) {
    throw err;
  }
};

// fetchQuotesList

export const fetchQuotesList = async ({ limit, page }: { limit: number; page: number }) => {
  try {
    const response = await fetch(
      `${envConfig.publicServerUrl}/api/v1/quotes/article-periodicals?limit=${limit}&page=${page}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        cache: "no-cache",
      },
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();
    return respData;
  } catch (err) {
    console.error("Error:", err);
    return err;
  }
};

export const fetchTotalQuotesCount = async () => {
  try {
    const response = await fetch(envConfig.publicQraphqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: QUERY_TOTAL_QUOTES_COUNT,
      }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();
    return (respData?.data?.Quotes?.totalDocs || 0) as number;
  } catch (err) {
    console.log(err);
  }
};

export const fetchAllQuotesSlug = async ({
  page = 1,
  limit = 10,
}: {
  page?: number;
  limit?: number;
}) => {
  try {
    const response = await fetch(envConfig.publicQraphqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: QUERY_QUOTES_SLUG,
        variables: {
          page,
          limit,
        },
      }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();
    return (respData?.data?.Quotes?.docs || []) as QuoteDetailType[];
  } catch (err) {
    console.log(err);
  }
};
