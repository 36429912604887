"use client";

import Link from "next/link";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import Avatar from "@/components/shared/Avatar";
import SectionHeader from "@/components/shared/SectionHeader";
import { Typography } from "@/components/shared/Typography";
import { fetchInterviews } from "@/services/interviewsService";
import { InterviewBlockType } from "@/types/page";
import InterviewsList from "./InterviewsList";
import { getImageUrl } from "@/utils/getImageUrl";
import SimpleLoader from "@/components/shared/SimpleLoader";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function InterviewBlock({ block }: { block: InterviewBlockType }) {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  const { data, error, isFetching } = useQuery({
    queryKey: ["fetchInterviews"],
    queryFn: () =>
      fetchInterviews({
        limit: block?.limit,
      }),
    refetchOnWindowFocus: false,
    enabled: isVisible,
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target); // Once it's visible, stop observing
        }
      },
      {
        threshold: [0.1],
        rootMargin: "500px 0px 500px 0px",
      },
    );

    const currentSection = sectionRef?.current;

    if (currentSection) {
      observer.observe(currentSection);
    }

    return () => {
      if (currentSection) {
        observer.unobserve(currentSection);
      }
    };
  }, []);

  if (!data || !isVisible || isFetching) {
    return (
      <section ref={sectionRef} className="h-[100px]">
        <SimpleLoader />
      </section>
    );
  }

  return (
    <section ref={sectionRef} className="relative py-20 bg-[#f0f0f0] dark:bg-[#6d6d6d]">
      <img
        src="/images/interviewbg.webp"
        alt="interview-bg"
        loading="lazy"
        className="absolute top-0 left-0 object-cover w-full h-full"
      />

      <div className="relative flex justify-center">
        <SectionHeader
          title={block?.title}
          iconBgColor="#11B496"
          titleColor="#fff"
          link="/interviews"
        />
      </div>
      <div className="container relative mt-20">
        <Link
          href={`/interviews/${data[0]?.slug}`}
          className="flex flex-col justify-between max-w-screen-md mx-auto sm:flex-row"
          aria-label={`View interview: ${data[0]?.title}`}
        >
          <div className="relative flex flex-col items-center gap-5 px-4 sm:items-start shrink-0">
            <Avatar
              image={getImageUrl(data[0]?.image?.sizes?.ld?.url || data[0]?.image?.url)}
              showQuoteMark
              width={80}
              height={80}
            />
            <div>
              <Typography variant="h3" color="#fff">
                {data[0]?.interviewee}
              </Typography>
              <Typography variant="body1" color="#fff">
                {data[0]?.role}
              </Typography>
            </div>
          </div>
          <div className="border-b sm:border-l"></div>
          <div className="flex flex-col gap-5 px-4">
            <Typography variant="h2" language="ml" color="#fff" className="line-clamp-2">
              {data[0]?.title}
            </Typography>
            <Typography variant="body1" language="ml" color="#fff" className="line-clamp-4">
              {data[0]?.summary}
            </Typography>
          </div>
        </Link>
      </div>

      {/* Carousal part */}
      <InterviewsList interviews={data || []} />
    </section>
  );
}
