"use client";

import { useEffect, useRef, useState } from "react";
import moment from "moment";
import Link from "next/link";
import { LiaSquareFullSolid } from "react-icons/lia";
import { useQuery } from "@tanstack/react-query";

import SectionHeader from "@/components/shared/SectionHeader";
import { Typography } from "@/components/shared/Typography";
import { TitleNewsBlockType } from "@/types/page";
import { fetchConditionalNewsAPI, fetchNewsTitles } from "@/services/newsService";
import { getNewsDetailsUrl } from "@/utils/getNewsDetailsUrl";
import TitleNewsBlockSkeleton from "./TitleNewsBlockSkeleton";
import TitleTag from "@/components/shared/TitleTag";
import { usePathname } from "next/navigation";

export default function TitleNewsBlock({ block, pageSlug }: { block: TitleNewsBlockType, pageSlug: string }) {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  const pathname = usePathname();
  const language = pathname === "/english" ? "en" : "ml";

  const { data, error, isFetching } = useQuery({
    queryKey: ["fetchNewsTitles"],
    queryFn: () => fetchConditionalNewsAPI({ block, pageSlug, limit: block?.limit || 4 })
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target); // Once it's visible, stop observing
        }
      },
      {
        threshold: [0.1],
        rootMargin: "500px 0px 500px 0px",
      },
    );

    const currentSection = sectionRef?.current;

    if (currentSection) {
      observer.observe(currentSection);
    }

    return () => {
      if (currentSection) {
        observer.unobserve(currentSection);
      }
    };
  }, []);

  return (
    <section
      ref={sectionRef}
      style={
        block?.backgroundColor !== "#ffffff" ? { backgroundColor: block?.backgroundColor } : {}
      }
      className="px-2  "
    >
      <SectionHeader
        titleColor={block?.textColor !== "#121212" ? block?.textColor : undefined}
        title={block?.title}
        showLogo={false}
        link={block?.orderBy === "latest" ? "/latest-news" : undefined}
      />
      <div className="mt-8">
        {!data || !isVisible || isFetching ? (
          <TitleNewsBlockSkeleton limit={block?.limit || 4} />
        ) : (
          <div className="flex grid-cols-4 gap-12 overflow-x-scroll no-scrollbar md:grid">
            {data?.map((article: any, index: number) => (
              <div key={index}>
                <div className="flex items-center w-full gap-3 mb-4 min-w-60 md:min-w-max">
                  <Typography
                    variant="body3"
                    className="uppercase whitespace-nowrap"
                    color="#FF0000"
                  >
                    {moment(article?.publishedAt).fromNow()}
                  </Typography>
                  <span className="flex items-center w-full text-primaryRed">
                    <LiaSquareFullSolid size={10} />
                    <span className="w-full border-t border-dashed border-primaryRed dark:border-primaryRed"></span>
                  </span>
                </div>
                <Link
                  href={getNewsDetailsUrl(
                    article?.categoryId,
                    article?.subCategoryId,
                    article?.slug,
                  )}
                  aria-label={`View news: ${article?.title}`}
                >
                  <Typography
                    color={block?.textColor !== "#121212" ? block?.textColor : undefined}
                    language="ml"
                    variant="h5"
                    className="line-clamp-3"
                  >
                    {article?.isLiveNews && <TitleTag content="Live News" />}
                    {article?.isInPrint && <TitleTag content="print edition" />}
                    {article?.title}
                  </Typography>
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
}
