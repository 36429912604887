"use client";

import React, { useEffect, useRef, useState } from "react";
import Link from "next/link";
import { FaPlay } from "react-icons/fa";
import { useQuery } from "@tanstack/react-query";

import CustomImage from "@/components/shared/CustomImage";
import SectionHeader from "@/components/shared/SectionHeader";
import { Typography } from "@/components/shared/Typography";
import { fetchPodcast } from "@/services/podcastService";
import { getImageUrl } from "@/utils/getImageUrl";
import SimpleLoader from "@/components/shared/SimpleLoader";
import { PodcastBlockType } from "@/types/page";

export default function PodcastBlock({ block }: { block: PodcastBlockType }) {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  const { data, error, isFetching } = useQuery({
    queryKey: ["fetchPodcast", block?.category],
    queryFn: () => fetchPodcast({ limit: 4, page: 1, categoryId: block?.category }),
    refetchOnWindowFocus: false,
    enabled: isVisible,
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target); // Once it's visible, stop observing
        }
      },
      {
        threshold: [0.1],
        rootMargin: "500px 0px 500px 0px",
      },
    );

    const currentSection = sectionRef?.current;

    if (currentSection) {
      observer.observe(currentSection);
    }

    return () => {
      if (currentSection) {
        observer.unobserve(currentSection);
      }
    };
  }, []);

  if (!data || !isVisible || isFetching) {
    return (
      <section ref={sectionRef} className="h-[100px]">
        <SimpleLoader />
      </section>
    );
  }

  return (
    <section className="bg-[#121212] py-8">
      <div className="container">
        <div className="flex justify-center">
          <SectionHeader title={block?.title} titleColor="#fff" link="/podcasts" />
        </div>
        <div className="flex justify-end">
          <Link href="/podcasts" className="text-white" aria-label="Go to podcasts page">
            View More
          </Link>
        </div>
        <div className="grid gap-8 mt-8 sm:grid-cols-2 md:gric-cols-3 lg:grid-cols-4">
          {data?.docs?.map((podcast: any, index: number) => {
            
            return (
              <div className="group" key={index}>
                <Link
                  href={`/podcasts/${podcast.slug}`}
                  aria-label={`View podcast: ${podcast?.title}`}
                >
                  <div className="relative w-full aspect-[16/10]">
                    <div className="w-full aspect-[16/10] overflow-hidden rounded-xl">
                      <CustomImage
                        src={getImageUrl(
                          podcast?.thumbnail?.sizes?.ld?.filename || podcast?.thumbnail?.filename,
                        )}
                        className={`w-full h-full object-cover img-zoom-hover rounded-xl`}
                        alt={podcast?.thumbnail?.image?.alt || ""}
                      />
                    </div>

                    <div className="absolute -top-2 left-6 w-[50px] h-[50px] bg-[#00FFCC] flex items-center justify-center rounded-full">
                      <span className="text-[30px] font-anek">
                        {`${index + 1}`.padStart(2, "0")}
                      </span>
                    </div>
                  </div>
                </Link>
                <div className="mt-3">
                  <Link
                    href={`/podcasts/${podcast.slug}`}
                    aria-label={`View podcast: ${podcast?.title}`}
                  >
                    <Typography language="ml" variant="h6" className="text-white line-clamp-3">
                      {podcast?.title}
                    </Typography>
                  </Link>
                </div>
                {podcast?.categoryId && (
                  <div className="flex gap-2 mt-2 divide-x-2">
                    <Typography language="ml" variant="body2" className="text-white">
                      {podcast?.categoryId?.name}
                    </Typography>
                    {podcast?.subCategoryId && (
                      <Typography language="ml" variant="body2" className="text-white pl-2">
                        {podcast?.subCategoryId?.name}
                      </Typography>
                    )}
                  </div>
                )}
                <div className="mt-5">
                  <Link
                    href={`/podcasts/${podcast.slug}`}
                    aria-label={`View podcast: ${podcast?.title}`}
                  >
                    <button
                      className="flex justify-between gap-3 px-3 py-2 bg-white rounded-full"
                      aria-label="Start Listening"
                    >
                      <span>Start Listening</span>
                      <span className="flex items-center justify-center w-5 h-5 bg-black rounded-full">
                        <FaPlay size={8} color="#fff" className="" />
                      </span>
                    </button>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
