"use client";

import React, { useRef, useState } from "react";
import { SlOptionsVertical } from "react-icons/sl";
import { FaLink } from "react-icons/fa";
import { MdMore } from "react-icons/md";

import { useMessage } from "../providers/MessageProvider";
import { useHandleClickOutside } from "@/hooks";
import envConfig from "@/config/envConfig";
import Link from "next/link";

interface NewsMoreOptionProps {
  url: string;
  moreUrl: string;
}

export default function NewsMoreOption({ url, moreUrl }: NewsMoreOptionProps) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const { showMessage } = useMessage();

  useHandleClickOutside(dropdownRef, () => setIsDropdownOpen(false));

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`${envConfig?.clientUrl}${url}`);
    showMessage("info", "Link copied to clipboard!");
  };

  return (
    <div className="relative">
      <button
        className="flex items-center justify-center"
        onClick={() => setIsDropdownOpen(true)}
        aria-label="More Options"
      >
        <SlOptionsVertical />
      </button>

      {isDropdownOpen && (
        <div
          ref={dropdownRef}
          className="absolute top-[100%] right-0 white shadow-md z-10 bg-white min-w-[180px] py-3 rounded"
        >
          <Link href={moreUrl}>
            <div className="flex items-center gap-2 cursor-pointer px-4 py-2 transition-colors hover:text-[#0055a5]">
              <span className="flex items-center justify-center">
                <MdMore />
              </span>
              <span className="text-sm font-medium whitespace-nowrap">More News</span>
            </div>
          </Link>
          <div
            className="flex items-center gap-2 cursor-pointer px-4 py-2 transition-colors hover:text-[#0055a5]"
            onClick={handleCopyLink}
          >
            <span className="flex items-center justify-center">
              <FaLink />
            </span>
            <span className="text-sm font-medium whitespace-nowrap">Copy Link</span>
          </div>
        </div>
      )}
    </div>
  );
}
