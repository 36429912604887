"use client";

import { useEffect, useState } from "react";
import { TbDatabaseOff } from "react-icons/tb";

import { Typography } from "@/components/shared/Typography";
import { fetchSpecialEvents } from "@/services/commonService";
import { ElectionDataBlockType, ElectionDataType } from "@/types/page";
import SimpleLoader from "@/components/shared/SimpleLoader";

export default function ElectionDataBlock({ block }: { block: ElectionDataBlockType }) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<ElectionDataType | null>(null);
  const [visibleContentIndex, setVisibleContentIndex] = useState(0);
  const ITEMS_TO_SHOW = data?.showPreviousYear ? 4 : 3;
  const ROTATION_INTERVAL = 5000; // 5 seconds

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetchSpecialEvents();
      setData(result?.electionData);
      setIsLoading(false);
    };

    if (block) {
      if (block?.dataSource !== "static") {
        fetchData();
      } else {
        setData(block?.electionData);
        setIsLoading(false);
      }
    }
  }, [block]);

  useEffect(() => {
    if (!data?.contentWithLinks?.length) return;

    const maxStartIndex = Math.max(0, data.contentWithLinks?.length - ITEMS_TO_SHOW);
    const interval = setInterval(() => {
      setVisibleContentIndex(prevIndex =>
        prevIndex >= maxStartIndex ? 0 : prevIndex + ITEMS_TO_SHOW,
      );
    }, ROTATION_INTERVAL);

    return () => clearInterval(interval);
  }, [data?.contentWithLinks?.length]);

  const getVisibleContent = () => {
    if (!data?.contentWithLinks?.length) return [];
    return data.contentWithLinks.slice(visibleContentIndex, visibleContentIndex + ITEMS_TO_SHOW);
  };

  if (isLoading) return <SimpleLoader />;

  return (
    <div className="grid-cols-2 gap-6 lg:grid max-w-[1100px] mx-auto">
      <div className="bg-white dark:bg-[#3e3e3e] dark:border rounded-lg shadow-[0_4px_10px_rgba(0,0,0,0.08),0_2px_5px_rgba(0,0,0,0.06)]  h-full">
        <div className="p-4 lg:p-6">
          <div className="flex items-center justify-between gap-4 mb-4">
            {data?.link ? (
              <a href={data?.link} target="_blank">
                <Typography variant="h5">{data?.title}</Typography>
              </a>
            ) : (
              <Typography variant="h5">{data?.title}</Typography>
            )}
            <div className="border border-red-500 rounded-full p-[1px]">
              <span className="block w-2 h-2 bg-red-500 rounded-full animate-pulse"></span>
            </div>
          </div>
          <div className="flex items-center gap-3">
            {data?.partyResults.map((result, index) => (
              <div
                key={index}
                className="flex-1 flex flex-col items-center justify-center text-white aspect-square max-h-[100px] text-center rounded-xl transition-all hover:-translate-y-1 min-w-0"
                style={{ backgroundColor: result?.color }}
              >
                <span className="block text-2xl font-bold lg:font-extrabold lg:text-4xl">
                  {result?.seats?.toString().padStart(2, "0")}
                </span>
                <span className="block text-sm font-medium uppercase lg:text-base">
                  {result?.party}
                </span>
              </div>
            ))}
          </div>
        </div>
        {data?.showPreviousYear && (
          <>
            <span className="block w-full mt-2 mb-2 border border-dashed"></span>
            <div className="p-4">
              <div className="items-center justify-center gap-4 p-2 border rounded-lg lg:flex">
                <span className="block mb-2 font-medium lg:hidden dark:text-white">
                  Result {data?.previousResults?.year}
                </span>
                <span className="hidden font-medium lg:block dark:text-white">
                  {data?.previousResults?.year}
                </span>
                <div className="flex flex-wrap items-center gap-2">
                  {data?.previousResults?.parties.map((party, index) => (
                    <div
                      key={index}
                      className="pr-2 border-r last:border-r-0 border-r-[#777] dark:border-r-[#ddd]"
                    >
                      <span className="uppercase text-[#878787] dark:text-[#a7a7a7] text-sm font-medium">
                        {party?.partyName}
                      </span>
                      <span
                        className="ml-2 font-medium text-[15px]"
                        style={{ color: party?.color }}
                      >
                        {party?.seats?.toString()?.padStart(2, "0")}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="relative mt-5 lg:mt-0 bg-white dark:bg-darkBgSecondaryColor shadow-[0_4px_10px_rgba(0,0,0,0.08),0_2px_5px_rgba(0,0,0,0.06)] rounded-lg p-4">
        {!data?.contentWithLinks || data?.contentWithLinks?.length < 1 ? (
          <div className="flex flex-col items-center justify-center h-full dark:text-white">
            <TbDatabaseOff size={20} />
            <span className="block mt-2 text-sm font-medium text-center ">No Data Found!</span>
          </div>
        ) : (
          <>
            <div className="relative transition-all duration-500 ease-in-out">
              {getVisibleContent().map((content, index) => (
                <div
                  key={index}
                  className="flex items-start gap-2 mb-2 text-base font-semibold last:mb-0 "
                >
                  <span className="text-red-500">&#10095;&#10095;</span>
                  {content?.link ? (
                    <a href={content?.link} target="_blank">
                      <span className="block dark:text-white hover:text-red-500">
                        {content?.content}
                      </span>
                    </a>
                  ) : (
                    <span className="block dark:text-white">{content?.content}</span>
                  )}
                </div>
              ))}
            </div>
            <div className="absolute flex gap-1 bottom-2 right-2">
              {Array.from({
                length: Math.ceil(data?.contentWithLinks?.length / ITEMS_TO_SHOW),
              }).map((_, index) => (
                <div
                  key={index}
                  className={`w-1.5 h-1.5 rounded-full transition-all duration-300 ${
                    Math.floor(visibleContentIndex / ITEMS_TO_SHOW) === index
                      ? "bg-red-500"
                      : "bg-gray-300"
                  }`}
                ></div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
