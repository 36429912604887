"use client";

export type ArticleCarousalCardSkeletonProps = {};

export default function ArticleCarousalCardSkeleton({}: ArticleCarousalCardSkeletonProps) {
  return (
    <div className="w-full pr-4">
      <span className="block h-[24px] w-full bg-slate-300 transition-all animate-pulse"></span>
      <span className="block h-[24px] w-full bg-slate-300 transition-all animate-pulse mt-2"></span>
      <span className="block h-[24px] w-[60%] bg-slate-300 transition-all animate-pulse mt-2"></span>

      <span className="block  w-full aspect-video bg-slate-300 rounded-md transition-all animate-pulse mt-4"></span>

      <div className="flex gap-4 divide-x-2 divide-black  uppercase mt-3">
        <span className="block h-[14px] w-[100px] bg-slate-300 transition-all animate-pulse"></span>
        <div className="pl-4">
          <span className="block h-[14px] w-[100px] bg-slate-300 transition-all animate-pulse"></span>
        </div>
      </div>

      <div className="mt-4">
        <span className="block h-[16px] w-full bg-slate-300 transition-all animate-pulse"></span>
        <span className="block h-[16px] w-full bg-slate-300 transition-all animate-pulse mt-2"></span>
        <span className="block h-[16px] w-[70%] bg-slate-300 transition-all animate-pulse mt-2"></span>
      </div>

      <span className="block h-[16px] w-[25%] bg-slate-300 transition-all animate-pulse mt-3"></span>
    </div>
  );
}
