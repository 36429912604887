import envConfig from "@/config/envConfig";
import {
  QUERY_SHORTS_SLUG,
  QUERY_TOTAL_SHORTS_COUNT,
  SHORTS,
  SHORTS_BY_SLUG,
} from "@/graphql/shorts";
import { ShortType } from "@/types/shorts";

export const fetchShorts = async ({
  categoryId,
  subCategoryId,
  limit = 10,
  page = 1,
  sort = "-createdAt",
  cache = "no-cache",
}: {
  categoryId?: string;
  subCategoryId?: string;
  limit?: number;
  page?: number;
  sort?: "createdAt" | "-createdAt";
  cache?: RequestCache;
}) => {
  try {
    const response = await fetch(envConfig.publicQraphqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        // query: SHORTS,
        query: SHORTS({ categoryId, subCategoryId }),
        variables: {
          limit,
          page,
          sort,
        },
      }),
      cache,
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();

    return {
      docs: (respData?.data?.Shorts?.docs || []) as ShortType[],
      totalDocs: respData?.data?.Shorts?.totalDocs || 0,
    };
  } catch (err) {
    throw err;
  }
};

export const fetchSingleShort = async ({
  slug,
  cache = "no-cache",
}: {
  slug: string;
  cache?: RequestCache;
}) => {
  try {
    const response = await fetch(`${envConfig.publicServerUrl}/api/v1/shorts/single/${slug}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      cache,
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const respData = await response?.json();
    return {
      currentShort: (respData?.currentShort as ShortType) || null,
      previousShort: (respData?.previousShort as ShortType) || null,
      nextShort: (respData?.nextShort as ShortType) || null,
    };
  } catch (err) {
    throw err;
  }
};

export const fetchSingleShortMetaData = async ({
  slug,
  cache = "no-cache",
}: {
  slug: string;
  cache?: RequestCache;
}) => {
  try {
    const response = await fetch(envConfig.publicQraphqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: SHORTS_BY_SLUG,
        variables: {
          slug,
        },
      }),
      cache,
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();
    return (respData?.data?.Shorts?.docs[0] || null) as ShortType;
  } catch (err) {
    throw err;
  }
};

export const postShortLike = async ({
  shortsId,
  isLiked,
}: {
  shortsId: string;
  isLiked: boolean;
}) => {
  try {
    const response = await fetch(`${envConfig.publicServerUrl}/api/v1/shorts/like`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shortsId,
        isLiked,
      }),
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error("Something went wrong!");
    }

    const respData = await response.json();
    return respData;
  } catch (err) {
    throw err;
  }
};

export const fetchShortLikeStatus = async ({ shortsId }: { shortsId: string }) => {
  try {
    const response = await fetch(
      `${envConfig.publicServerUrl}/api/v1/shorts/like-status/${shortsId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      },
    );

    if (!response.ok) {
      throw new Error("Something went wrong!");
    }

    const respData = await response.json();
    return respData as { isLiked: boolean; likesCount: number };
  } catch (err) {
    throw err;
  }
};

export const fetchTotalShortsCount = async () => {
  try {
    const response = await fetch(envConfig.publicQraphqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: QUERY_TOTAL_SHORTS_COUNT,
      }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();
    return (respData?.data?.Shorts?.totalDocs || 0) as number;
  } catch (err) {
    console.log(err);
  }
};

export const fetchAllShortsSlug = async ({
  page = 1,
  limit = 10,
}: {
  page?: number;
  limit?: number;
}) => {
  try {
    const response = await fetch(envConfig.publicQraphqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: QUERY_SHORTS_SLUG,
        variables: {
          page,
          limit,
        },
      }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();
    return (respData?.data?.Shorts?.docs || []) as ShortType[];
  } catch (err) {
    console.log(err);
  }
};
