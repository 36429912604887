import envConfig from "@/config/envConfig";
import {
  QUERY_PHOTO_STORIES,
  QUERY_PHOTOSTORIES_SLUG,
  QUERY_TOTAL_PHOTOSTORIES_COUNT,
} from "@/graphql/photoStories";
import { PhotogrpaherPhotosType, PhotoStoryType } from "@/types/photoStory";

export const fetchPhotoStories = async ({
  categoryId,
  limit = 10,
  page = 1,
  sort = "-createdAt",
  featured,
}: {
  categoryId?: string;
  limit?: number;
  page?: number;
  sort?: "createdAt" | "-createdAt";
  featured?: string;
}) => {
  try {
    const response = await fetch(envConfig.publicQraphqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      next: {
        revalidate: 60, // Revalidate every 60 seconds
      },
      body: JSON.stringify({
        query: QUERY_PHOTO_STORIES({ categoryId, featured }),
        variables: {
          limit,
          page,
          sort,
        },
      }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();
    return {
      docs: (respData?.data?.PhotoStories?.docs || []) as PhotoStoryType[],
      totalDocs: respData?.data?.PhotoStories?.totalDocs || 0,
    };
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const fetchPhotoStory = async ({ id }: { id: string }) => {
  try {
    const response = await fetch(envConfig.publicServerUrl + `/api/photo-stories/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      next: {
        revalidate: 60, // Revalidate every 60 seconds
      },
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();
    return respData as PhotoStoryType;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const fetchSinglePhotoStory = async ({
  slug,
  cache = "no-cache",
}: {
  slug: string;
  cache?: RequestCache;
}) => {
  try {
    const response = await fetch(
      envConfig.publicServerUrl + `/api/v1/photo-stories/single/${slug}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        cache,
      },
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();
    return respData?.photoStory as PhotoStoryType;
  } catch (err) {
    throw err;
  }
};

export const fetchTotalPhotoStoriesCount = async () => {
  try {
    const response = await fetch(envConfig.publicQraphqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: QUERY_TOTAL_PHOTOSTORIES_COUNT,
      }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();
    return (respData?.data?.PhotoStories?.totalDocs || 0) as number;
  } catch (err) {
    console.log(err);
  }
};

export const fetchAllPhotoStoriesSlug = async ({
  page = 1,
  limit = 10,
}: {
  page?: number;
  limit?: number;
}) => {
  try {
    const response = await fetch(envConfig.publicQraphqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: QUERY_PHOTOSTORIES_SLUG,
        variables: {
          page,
          limit,
        },
      }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();
    return (respData?.data?.PhotoStories?.docs || []) as PhotoStoryType[];
  } catch (err) {
    console.log(err);
  }
};

export const fetchSinglePhotographerPhotos = async ({
  slug,
  limit = 10,
  page = 1,
}: {
  slug: string;
  limit?: number;
  page?: number;
}) => {
  try {
    const response = await fetch(
      envConfig.publicServerUrl +
        `/api/v1/photo-stories/photographer/${slug}?limit=${limit}&page=${page}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        next: {
          revalidate: 60, // Revalidate every 60 seconds
        },
      },
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();
    return respData as PhotogrpaherPhotosType;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
