"use client";

import { useState } from "react";
import { BsArrowUpRight } from "react-icons/bs";
import { FaArrowRight } from "react-icons/fa";

import { subscribeUser } from "../../services/userService";

export const Subscription = ({ footer }: { footer: boolean }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [modal, setModal] = useState({ confirm: false, success: false });

  const handleSubscribe = async () => {
    setError("");
    try {
      await subscribeUser(email);
      setModal({ confirm: false, success: true });
      setEmail("");
    } catch (err: any) {
      setModal({ confirm: false, success: false });

      setError("You are already subscribed.");
    }
  };

  const closeAllModals = () => {
    setModal({ confirm: false, success: false });
  };

  const openConfirmModal = () => {
    if (!email) {
      setError("Email is required");
      return;
    }
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (!regex.test(email)) {
      setError("Valid email is required");
      setModal({ confirm: false, success: false });
      return;
    }
    setError("");
    setModal({ confirm: true, success: false });
  };

  return (
    <>
      {footer ? (
        <div aria-live="polite" className="relative">
          <input
            type="email"
            placeholder="example@gmail.com"
            value={email}
            onChange={e => setEmail(e.target.value)}
            onKeyDown={e => {
              if (e.key === "Enter") openConfirmModal();
            }}
            className="w-full p-1 text-sm bg-transparent border-b outline-none placeholder:text-primaryTextGray text-secondaryGray border-primaryTextGray dark:text-white"
          />
          <button
            className="absolute cursor-pointer right-4 -top-2 text-primaryRed text-2xl p-1"
            onClick={openConfirmModal}
            aria-label="Subscribe"
          >
            <FaArrowRight />
          </button>
        </div>
      ) : (
        <div
          aria-live="polite"
          className="border border-[#262626] dark:border-darkBgSecondaryColor h-[45px] flex items-center justify-between mt-8 rounded"
        >
          <input
            type="text"
            className="bg-transparent outline-none px-[16px] w-[350px] max-w-full dark:text-white"
            placeholder="example@gmail.com"
            value={email}
            onChange={e => setEmail(e.target.value)}
            onKeyDown={e => {
              if (e.key === "Enter") openConfirmModal();
            }}
          />
          <button
            className="bg-[#262626] dark:bg-[#EBEEF3] h-full w-[100px] flex items-center justify-center text-white dark:text-black"
            onClick={openConfirmModal}
            aria-label="Subscribe"
          >
            <BsArrowUpRight size={25} />
          </button>
        </div>
      )}
      {error ? (
        <p aria-live="polite" className="mt-2 text-xs text-red-500">
          {error}
        </p>
      ) : (
        <p className="h-4 mt-2" />
      )}

      {/* Confirmation Modal */}
      {modal.confirm && (
        <div
          aria-live="polite"
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
        >
          <div className="p-6 text-center bg-white rounded-lg shadow-lg">
            <h2 className="text-xl font-medium">Confirm Subscription</h2>
            <p className="mt-2 text-gray-700">
              Subscribe to receive weekly top stories straight to your inbox.
            </p>
            <div>
              <button
                onClick={closeAllModals}
                className="px-4 py-2 mt-4 text-black bg-transparent rounded"
                aria-label="Cancel"
              >
                Cancel
              </button>
              <button
                onClick={handleSubscribe}
                className="px-4 py-2 mt-4 text-white bg-blue-600 rounded"
                aria-label="Confirm"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Success Modal */}
      {modal.success && (
        <div
          aria-live="polite"
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
        >
          <div className="p-6 text-center bg-white rounded-lg shadow-lg">
            <h2 className="text-xl font-medium">Subscription Successful</h2>
            <p className="mt-2 text-gray-700">
              Thank you for subscribing! You&#39;ll now receive the top news updates every week.
              Stay tuned!
            </p>
            <button
              onClick={closeAllModals}
              className="px-4 py-2 mt-4 text-white rounded bg-primaryRed"
              aria-label="Close"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};
