"use client";

import Link from "next/link";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { VisualStoriesBlockType } from "@/types/page";
import SectionHeader from "../shared/SectionHeader";
import { fetchVisualStories } from "@/services/visualStoriesService";
import CustomImage from "../shared/CustomImage";
import { Typography } from "../shared/Typography";
import { getImageUrl } from "@/utils/getImageUrl";
import SimpleLoader from "../shared/SimpleLoader";

export default function VisualStoriesBlock({ block }: { block: VisualStoriesBlockType }) {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  const { data, error, isFetching } = useQuery({
    queryKey: ["fetchVisualStories"],
    queryFn: () =>
      fetchVisualStories({
        limit: 4,
        categoryId: block?.category,
      }),
    refetchOnWindowFocus: false,
    enabled: isVisible,
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target); // Once it's visible, stop observing
        }
      },
      {
        threshold: [0.1],
        rootMargin: "500px 0px 500px 0px",
      },
    );

    const currentSection = sectionRef.current;

    if (currentSection) {
      observer.observe(currentSection);
    }

    return () => {
      if (currentSection) {
        observer.unobserve(currentSection);
      }
    };
  }, []);

  if (!data || !isVisible || isFetching) {
    return (
      <section ref={sectionRef} className="h-[100px]">
        <SimpleLoader />
      </section>
    );
  }

  return (
    <section ref={sectionRef}>
      <div className="flex items-center justify-between gap-4">
        <SectionHeader title={block?.title} link="/visual-stories" />
        <Link href={`/visual-stories`} aria-label="Go to visual stories">
          <div className="flex items-center gap-2">
            <span className="text-[13px] uppercase font-medium dark:text-gray-400">
              More Visual Story
            </span>
            <span className="text-red-500">
              <MdKeyboardDoubleArrowRight />
            </span>
          </div>
        </Link>
      </div>
      <div className="grid grid-cols-1 mt-8 sm:grid-cols-3 gap-x-4 gap-y-12">
        {data?.docs?.map((story, index) => {
          const { thumbnail, title, content } = story;

          if (index === 0) {
            return (
              <Link
                key={index}
                target="_blank"
                href={`/visual-stories/${story?.slug}`}
                className="relative col-span-1 cursor-pointer sm:col-span-3 group"
                aria-label={`View visual story: ${title}`}
              >
                <div className="w-full h-[500px] rounded-md overflow-hidden">
                  <CustomImage
                    src={getImageUrl(thumbnail?.sizes?.md?.url || thumbnail?.url)}
                    alt={thumbnail?.alt || ""}
                    className="object-cover w-full h-full img-zoom-hover"
                    phImg="/images/placeholder-md.png"
                  />
                </div>

                <div className="absolute top-0 left-0 w-full h-full rounded-md bg-gradient-to-b from-transparent to-black"></div>

                <div className="absolute bottom-0 left-0 px-6 py-8">
                  <div
                    style={{ backgroundColor: block?.tagColor }}
                    className="w-[80px] h-[60px]  flex items-center justify-center rounded-md"
                  >
                    <span className="text-[55px] font-anek">{`${index + 1}`.padStart(2, "0")}</span>
                  </div>
                  <Typography variant="h2" color="#fff" language="ml" className="mt-5 line-clamp-2">
                    {title}
                  </Typography>
                  <Typography language="ml" color="#fff" className="mt-3 line-clamp-2">
                    {content}
                  </Typography>
                </div>
              </Link>
            );
          }

          return (
            <div key={index} className="group">
              <div className="relative w-full h-[200px] ">
                <Link
                  target="_blank"
                  href={`/visual-stories/${story?.slug}`}
                  aria-label={`View visual story: ${title}`}
                >
                  <div className="relative w-full h-full overflow-hidden rounded-md">
                    <CustomImage
                      src={getImageUrl(thumbnail?.sizes?.sd?.url || thumbnail?.url)}
                      className="object-cover w-full h-full img-zoom-hover"
                      alt={thumbnail?.alt || ""}
                      phImg="/images/placeholder-sd.png"
                    />
                  </div>
                </Link>

                <div
                  style={{ backgroundColor: block?.tagColor }}
                  className="absolute top-[-30px] left-[25px] w-[80px] h-[60px]  flex items-center justify-center rounded-md"
                >
                  <span className="text-[55px] font-anek">{`${index + 1}`.padStart(2, "0")}</span>
                </div>
              </div>
              <div className="mt-3">
                <Link
                  target="_blank"
                  href={`/visual-stories/${story?.slug}`}
                  aria-label={`View visual story: ${title}`}
                >
                  <Typography language="ml" variant="h6" className="line-clamp-2">
                    {title}
                  </Typography>
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}
