"use client";

import { useEffect, useRef, useState } from "react";
import Slider, { Settings } from "react-slick";

import { Typography } from "@/components/shared/Typography";
import { fetchSpecialEvents } from "@/services/commonService";
import { getImageUrl } from "@/utils/getImageUrl";
import SimpleLoader from "@/components/shared/SimpleLoader";
import { SliderWithHighlightBlockType } from "@/types/page";

const colors = ["#777ca2", "#409248", "#236abc", "#f44339", "#f9a42b", "#f36c2d"];

export default function SliderWithHighlightBlock({
  block,
}: {
  block: SliderWithHighlightBlockType;
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<{ highlights: []; imageTexts: [] }>({
    highlights: [],
    imageTexts: [],
  });
  const [currentIndex, setCurrentIndex] = useState(0);
  const [sliderHeight, setSliderHeight] = useState(0);

  const sliderWrapperRef = useRef<HTMLDivElement>(null);
  const slidesRef = useRef<(HTMLDivElement | null)[]>([]);

  const settings: Settings = {
    dots: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: data?.highlights?.length > 4,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: data?.highlights?.length > 4,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: data?.highlights?.length > 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: data?.highlights?.length > 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: data?.highlights?.length > 1,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetchSpecialEvents();
      setData(result);
      setIsLoading(false);
    };

    if (block) {
      if (block?.dataSource !== "static") {
        fetchData();
      } else {
        setData({ highlights: block?.highlights || [], imageTexts: block?.imageTexts || [] });
        setIsLoading(false);
      }
    }
  }, [block]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prev => (prev + 1) % data?.highlights?.length);
    }, 2500); // Change word every 2.5 seconds

    return () => clearInterval(interval);
  }, [data]);

  useEffect(() => {
    const updateHeight = () => {
      if (!data.imageTexts.length) return;

      slidesRef.current.forEach(slide => {
        if (slide) slide.style.minHeight = "auto";
      });

      // Get maximum height
      const heights = slidesRef.current.map(slide => {
        return slide ? slide.getBoundingClientRect().height : 0;
      });
      const maxHeight = Math.max(...heights);

      setSliderHeight(maxHeight);
    };

    updateHeight();
  }, [data?.imageTexts]);

  if (isLoading) return <SimpleLoader />;

  return (
    <div>
      <div className="w-full lg:flex items-center gap-4">
        <div>
          <h3 className="text-red-500 font-montserrat font-bold uppercase">
            {block?.title} &#10095;&#10095;&#10095;
          </h3>
        </div>
        <div className="relative h-[60px] overflow-hidden font-anek mt-2 lg:mt-0">
          <div
            className="transition-all duration-1000 ease-in-out dark:text-white"
            style={{
              transform: `translateY(-${currentIndex * 60}px)`,
            }}
          >
            {data.highlights.map((highlight: any, index: number) => (
              <div key={index} className="h-[60px] flex items-center text-base font-semibold ">
                {highlight?.link ? (
                  <a
                    href={highlight?.link}
                    target="_blank"
                    className="line-clamp-2 lg:line-clamp-1"
                  >
                    {highlight.highlightText}
                  </a>
                ) : (
                  <span className="line-clamp-2 lg:line-clamp-1">{highlight.highlightText}</span>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div ref={sliderWrapperRef}>
        <Slider {...settings}>
          {data?.imageTexts?.map((imageText: any, index: number) => {
            const color = colors[index % colors.length];
            return (
              <div key={index} className={"relative mt-6 sm:pr-4 "}>
                <div
                  ref={el => {
                    slidesRef.current[index] = el;
                  }}
                  className="relative bg-[#f1f1f1] dark:bg-[#6d6d6d] flex-1 h-full w-full p-4 rounded-md border-t-2 "
                  style={{
                    borderColor: color,
                    minHeight: `${sliderHeight}px`,
                  }}
                >
                  <Typography variant="h4" language="ml">
                    {imageText?.title}
                  </Typography>
                  <ul className="list-disc pl-4 dark:text-white">
                    {imageText?.content?.map((content: any, index: number) => {
                      return (
                        <li key={index} className="">
                          <Typography variant="body1" language="ml" className="mt-2 break-all">
                            {content?.point}
                          </Typography>
                        </li>
                      );
                    })}
                  </ul>

                  <div
                    className="absolute -top-[25px] left-[75%] -translate-x-[25%] w-[50px] h-[50px] rounded-full p-3"
                    style={{ backgroundColor: color }}
                  >
                    <img
                      src={getImageUrl(imageText?.image?.filename)}
                      alt=""
                      className="w-full h-full object-cover"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
}
