"use client";

import Link from "next/link";
import { BsThreeDots } from "react-icons/bs";
import { useCallback, useEffect, useRef, useState } from "react";

import { Typography } from "@/components/shared/Typography";
import { ArticleCategoryType } from "@/types/article";
import { useHandleClickOutside } from "@/hooks";
import { useRouter } from "next/navigation";

interface SubCategoriesListProps {
  category: ArticleCategoryType;
}

export default function SubCategoriesList({ category }: SubCategoriesListProps) {
  //
  const router = useRouter();
  //
  const [visibleCount, setVisibleCount] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  //
  const containerRef = useRef<HTMLDivElement | null>(null);
  const hiddenItemRef = useRef<HTMLDivElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const sectionRef = useRef(null);
  useHandleClickOutside(dropdownRef, () => setDropdownOpen(false));

  const calculateVisibleItems = useCallback(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.getBoundingClientRect().width;
      let totalWidth = 0;
      let count = 0;
      const gap = 16;
      const ellipsisSpace = 50;

      for (let i = 0; i < category?.subCategories.length; i++) {
        // const item = containerRef.current.children[i];
        const item = hiddenItemRef.current;

        if (!item) return;

        item.textContent = category?.subCategories[i]?.name;
        const itemWidth = item?.getBoundingClientRect()?.width;

        if (i === 0) {
          totalWidth += itemWidth;
          count++;
        } else if (totalWidth + itemWidth + gap + ellipsisSpace <= containerWidth) {
          totalWidth += itemWidth + gap;
          count++;
        } else {
          break;
        }
      }

      setVisibleCount(count);
    }
  }, [category?.subCategories]);

  useEffect(() => {
    const handleResize = () => calculateVisibleItems();
    calculateVisibleItems();

    window.addEventListener("resize", handleResize);

    return () => {
      return window.addEventListener("resize", handleResize);
    };
  }, [calculateVisibleItems]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target); // Once it's visible, stop observing
        }
      },
      {
        threshold: [0.1],
        rootMargin: "500px 0px 500px 0px",
      },
    );

    const currentSection = sectionRef?.current;

    if (currentSection) {
      observer.observe(currentSection);
    }

    return () => {
      if (currentSection) {
        observer.unobserve(currentSection);
      }
    };
  }, []);

  return (
    <div className="container" ref={sectionRef}>
      <div
        ref={containerRef}
        className="flex items-center gap-8 py-3 border-y border-y-[#AAA]"
      >
        <div className="flex items-center gap-4">
          {category?.subCategories?.slice(0, visibleCount)?.map((subCategory, index) => {
            return (
              <Link
                key={index}
                href={`/${category?.slug}/${subCategory?.slug}`}
                className={"cursor-pointer transition-all px-2"}
                aria-label={`Go to ${subCategory?.name} page`}
              >
                <Typography language="ml" className="whitespace-nowrap" variant="body2">
                  {subCategory?.name}
                </Typography>
              </Link>
            );
          })}
        </div>

        {visibleCount < category?.subCategories?.length && (
          <div className="relative">
            <button
              onClick={() => {
                setDropdownOpen(true);
              }}
              className="flex items-center justify-center dark:text-white"
              aria-label="More Categories"
            >
              <BsThreeDots />
            </button>

            {isDropdownOpen && (
              <div
                ref={dropdownRef}
                className="absolute right-0 top-[30px] bg-white dark:bg-darkBgSecondaryColor dark:text-white w-[210px] shadow-md border rounded z-10"
              >
                {category?.subCategories?.slice(visibleCount)?.map((subCategory, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        router.push(`/${category?.slug}/${subCategory?.slug}`);
                        setDropdownOpen(false);
                      }}
                      className="cursor-pointer transition-all hover:font-medium px-3 py-[5px] border-b "
                    >
                      <Typography language="ml" className="whitespace-nowrap" variant="body2">
                        {subCategory?.name}
                      </Typography>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </div>

      <span ref={hiddenItemRef} className="absolute invisible px-2 whitespace-nowrap"></span>
    </div>
  );
}
