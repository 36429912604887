"use client";

import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";

import HorizontalArticleCard from "@/components/cards/HorizontalArticleCard";
import { ArticleListType } from "@/types/article";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface TrendingNewsCarouselProps {
  articles: ArticleListType[];
}

export default function TrendingNewsCarousel({ articles }: TrendingNewsCarouselProps) {
  const [isLoading, setIsLoading] = useState(true);

  const sliderRef = useRef<Slider | null>(null);

  const settings = {
    dots: false,
    infinite: articles?.length > 1,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    arrows: false,
  };

  useEffect(() => {
    if (sliderRef.current) {
      setIsLoading(false);
    }
  }, []);

  return (
    <>
      {isLoading && (
        <div className="flex items-center gap-4 overflow-hidden">
          {articles?.slice(0, 4).map((article, index) => {
            return (
              <div
                key={index}
                className={
                  "flex items-start flex-1 gap-4 group " +
                  (index === 3
                    ? "hidden lg:flex"
                    : index === 2
                    ? "hidden md:flex"
                    : index === 1
                    ? "hidden sm:flex"
                    : "")
                }
              >
                <div className="pr-4" key={index}>
                  <HorizontalArticleCard variant="sm" article={article} index={index} />
                </div>
              </div>
            );
          })}
        </div>
      )}
      <Slider ref={sliderRef} {...settings} lazyLoad="ondemand">
        {articles?.map((article, index) => {
          return (
            <div className="pr-4" key={index}>
              <div className="bg-[#f5f5f5] dark:bg-[#6d6d6d] px-2 py-2 rounded-lg">
                <HorizontalArticleCard variant="sm" article={article} index={index} />
              </div>
            </div>
          );
        })}
      </Slider>
    </>
  );
}
