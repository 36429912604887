import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/src/components/blocks/AdvertisementBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/blocks/ArticlesAndPeriodicalsBlock/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/blocks/ElectionDataBlock/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/blocks/HorizontalNewsOverImageBlock/Slider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/blocks/InterviewBlock/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/blocks/PaginatedNewsListBlock/PaginatedNewsList.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/blocks/PhotoStoriesBlock.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/blocks/PodcastBlock/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/blocks/ShortsBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/blocks/SliderWithHighlightBlock/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/blocks/SpecialEventsBlock/YouthFestivalBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SubCategoryWiseNews"] */ "/app/src/components/blocks/SubCategoryWiseNewsBlock/SubCategoryWiseNews.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/blocks/ThreeCardBlock/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/blocks/TitleNewsBlock/TitleNewsBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/blocks/TopBarTrendingNews/TrendingNewsCarousel.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/blocks/VisualStoriesBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/cards/ArticleCarousalCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Subscription"] */ "/app/src/components/cards/Subscription.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/shared/CustomImage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/shared/NewsMoreOption.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/shared/RecommendedNews.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/shared/SocialShare.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/views/category/SubCategoriesList.tsx");
