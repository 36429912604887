import envConfig from "@/config/envConfig";
import {
  QUERY_NEXT_VISUAL_STORY,
  QUERY_SINGLE_VISUAL_STORY,
  QUERY_TOTAL_VISUALSTORIES_COUNT,
  QUERY_VISUAL_STORIES,
  QUERY_VISUALSTORIES_SLUG,
} from "@/graphql/visualStories";
import { VisualStoryType } from "@/types/visualStory";

export const fetchVisualStories = async ({
  categoryId,
  limit = 10,
  page = 1,
  sort = "-createdAt",
  cache = "no-cache",
}: {
  categoryId?: string;
  limit?: number;
  page?: number;
  sort?: "createdAt" | "-createdAt";
  cache?: RequestCache;
}) => {
  try {
    const response = await fetch(envConfig.publicQraphqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: QUERY_VISUAL_STORIES({ categoryId }),
        variables: {
          limit,
          page,
          sort,
        },
      }),
      cache,
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();
    return {
      docs: (respData?.data?.VisualStories?.docs || []) as VisualStoryType[],
      totalDocs: respData?.data?.VisualStories?.totalDocs || 0,
    };
  } catch (err) {
    throw err;
  }
};

export const fetchSingleVisualStory = async ({
  slug,
  cache = "no-cache",
}: {
  slug: string;
  cache?: RequestCache;
}) => {
  try {
    const response = await fetch(envConfig.publicQraphqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: QUERY_SINGLE_VISUAL_STORY,
        variables: {
          slug,
        },
      }),
      cache,
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();
    return respData?.data?.VisualStories?.docs[0] as VisualStoryType;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const fetchNextVisualStory = async ({
  id,
  createdAt,
}: {
  id: string;
  createdAt: Date;
  cache?: RequestCache;
}) => {
  try {
    console.log(id, createdAt);
    const response = await fetch(envConfig.publicQraphqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: QUERY_NEXT_VISUAL_STORY,
        variables: {
          currentId: id,
          createdAt,
        },
      }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();
    return respData?.data?.VisualStories?.docs[0] as VisualStoryType;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const fetchTotalVisualStoriesCount = async () => {
  try {
    const response = await fetch(envConfig.publicQraphqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: QUERY_TOTAL_VISUALSTORIES_COUNT,
      }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();
    return (respData?.data?.VisualStories?.totalDocs || 0) as number;
  } catch (err) {
    console.log(err);
  }
};

export const fetchAllVisualStoriesSlug = async ({
  page = 1,
  limit = 10,
}: {
  page?: number;
  limit?: number;
}) => {
  try {
    const response = await fetch(envConfig.publicQraphqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: QUERY_VISUALSTORIES_SLUG,
        variables: {
          page,
          limit,
        },
      }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();
    return (respData?.data?.VisualStories?.docs || []) as VisualStoryType[];
  } catch (err) {
    console.log(err);
  }
};
