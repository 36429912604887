import { FaQuoteLeft } from "react-icons/fa";
import Link from "next/link";

import { Typography } from "@/components/shared/Typography";
import { QuotesType } from "@/types/quotes";
import { getImageUrl } from "@/utils/getImageUrl";

export default function QuoteCard({ position, author, image, quote, slug }: QuotesType) {
  return (
    <Link href={`/articles-periodicals/${slug}`} aria-label={`View article: `}>
      <div className="border-b border-b-[#6B6B6B] pb-4 flex flex-col items-center md:items-start">
        <div className="relative w-[110px] h-[110px] rounded-full overflow-hidden">
          <img
            src={getImageUrl(image?.sizes?.ld?.url || image?.url) || "/images/avatar.webp"}
            alt={image?.alt ?? "Deshabhimani - ദേശാഭിമാനി ദിനപ്പത്രം"}
            className="object-cover w-full h-full filter grayscale"
            loading="lazy"
          />
        </div>
        <Typography variant="h6" className="mt-3 " language="ml" color="#fff">
          {author}
        </Typography>
        <Typography variant="body2" className="mt-1 " language="ml" color="#fff">
          {position}
        </Typography>
      </div>
      <div className="mt-4">
        <span className="text-2xl text-white">
          <FaQuoteLeft />
        </span>
        <Typography language="ml" color="#fff" className="mt-2 line-clamp-4" variant="body2">
          {quote}
        </Typography>
      </div>
    </Link>
  );
}
