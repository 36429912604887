"use client";

import moment from "moment";
import Link from "next/link";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useRef, useState } from "react";

import SectionHeader from "@/components/shared/SectionHeader";
import { Typography } from "@/components/shared/Typography";
import CustomImage from "@/components/shared/CustomImage";
import { fetchPhotoStories, fetchPhotoStory } from "@/services/photoStoriesService";
import { getImageUrl } from "@/utils/getImageUrl";
import { PhotoStoriesBlockType } from "@/types/page";
import SimpleLoader from "../shared/SimpleLoader";

export default function PhotoStoriesBlock({ block }: { block: PhotoStoriesBlockType }) {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  const { data: featured, isFetching: isFeaturedFetching } = useQuery({
    queryKey: ["fetchPhotoStory"],
    queryFn: () => fetchPhotoStory({ id: block.featuredStory || "" }),
    refetchOnWindowFocus: false,
    enabled: isVisible && !!block.featuredStory,
  });

  const { data, error, isFetching } = useQuery({
    queryKey: ["fetchPhotoStories"],
    queryFn: () =>
      fetchPhotoStories({
        limit: 5,
        categoryId: block?.category,
        featured: block.featuredStory || "",
      }),
    refetchOnWindowFocus: false,
    enabled: isVisible,
  });

  const featuredStory = useMemo(() => {
    return featured ?? data?.docs?.[0];
  }, [featured, data?.docs]);

  const photoStories = useMemo(() => {
    return block.featuredStory && featured ? data?.docs.slice(0, 4) : data?.docs.slice(1, 5);
  }, [block.featuredStory, featured, data?.docs]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target); // Once it's visible, stop observing
        }
      },
      {
        threshold: [0.1],
        rootMargin: "500px 0px 500px 0px",
      },
    );

    const currentSection = sectionRef.current;

    if (currentSection) {
      observer.observe(currentSection);
    }

    return () => {
      if (currentSection) {
        observer.unobserve(currentSection);
      }
    };
  }, []);

  if (!isVisible || !featuredStory || !photoStories || isFeaturedFetching || isFetching) {
    return (
      <section ref={sectionRef} className="h-[100px]">
        <SimpleLoader />
      </section>
    );
  }

  return (
    <section className="max-h-full">
      <div className="relative w-full h-full py-[50px]">
        <div className="absolute inset-0">
          <CustomImage
            src={getImageUrl(
              featuredStory?.thumbnail?.sizes?.md?.url || featuredStory?.thumbnail?.url || "",
            )}
            alt={featuredStory?.thumbnail?.alt}
            className="object-cover w-full h-full"
          />
        </div>

        <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-[#00000021] to-[#000]"></div>

        <div className="container relative flex flex-col items-center justify-between h-full">
          <div className="flex flex-col items-center text-center">
            <SectionHeader title="Photo Stories" titleColor="#fff" link="/photo-stories" />

            <Link
              href={`/photo-stories/news-in-pics/${featuredStory?.slug}`}
              aria-label={`View photo story: ${featuredStory?.title}`}
            >
              <Typography className=" max-w-[600px] mt-10 line-clamp-2" color="#fff" variant="h2">
                {featuredStory?.title}
              </Typography>
            </Link>
            <Typography color="#fff" className=" max-w-[600px] mt-5 line-clamp-4">
              {featuredStory?.content}
            </Typography>
          </div>

          <div className="mt-[60px]">
            <div className="flex items-center justify-between w-full gap-4">
              <span className="font-bold text-white uppercase">DISCOVER MORE +</span>
              <Link href={`/photo-stories`} aria-label="Go to photo stories">
                <span className="text-[#FF7A00] uppercase font-bold underline">VIEW ALL</span>
              </Link>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-y-16 gap-x-8 lg:gap-8 mt-[60px]">
              {photoStories?.map((photoStory, index) => {
                const { createdAt, title, thumbnail } = photoStory;

                return (
                  <div key={index} className="group">
                    <div className="relative w-full h-[200px]">
                      <Link
                        href={`/photo-stories/news-in-pics/${photoStory?.slug}`}
                        aria-label={`View photo story: ${featuredStory?.title}`}
                      >
                        <div className="w-full h-full overflow-hidden rounded-md">
                          <CustomImage
                            src={getImageUrl(thumbnail?.sizes?.sd?.url || thumbnail?.url)}
                            className="object-cover w-full h-full img-zoom-hover"
                            alt={thumbnail?.alt || ""}
                            phImg="/images/placeholder-sd.png"
                          />
                        </div>
                      </Link>

                      <div className="absolute top-[10px] left-[35px] transform -translate-x-1/2 bg-[#FF7A00] px-5  rotate-[270deg]">
                        <span className="text-sm font-bold uppercase whitespace-nowrap">
                          {moment(createdAt).format("MMMM DD")}
                        </span>
                      </div>
                    </div>
                    <Link
                      href={`/photo-stories/news-in-pics/${photoStory?.slug}`}
                      className="block mt-3"
                      aria-label={`View photo story: ${featuredStory?.title}`}
                    >
                      <Typography language="ml" variant="h6" color="#fff" className="line-clamp-3">
                        {title}
                      </Typography>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
