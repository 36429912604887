"use client";

import { fetchSpecialEvents } from "@/services/commonService";
import { YouthFestivalType } from "@/types/common";
import { SpecialEventsBlock } from "@/types/page";
import { useEffect, useState } from "react";
import VisualComponent from "./VisualComponent";

type Props = { mode: SpecialEventsBlock["mode"] };

export default function YouthFestivalBlock({ mode }: Props) {
  const [data, setData] = useState<YouthFestivalType | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetchSpecialEvents();
      setData(result);
    };

    fetchData();
  }, []);

  const leadingDistricts = data?.leadingDistrict?.sort((a, b) => b.point - a.point) || [];
  const topSchools = data?.performingSchools?.sort((a, b) => b.point - a.point) || [];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {leadingDistricts?.length > 0 && (
        <VisualComponent
          data={leadingDistricts}
          mode={mode}
          title="Leading District"
          iconSrc="/images/crown.png"
          tableHeaders={["#", "Districts", "Points"]}
        />
      )}
      {topSchools?.length > 0 && (
        <VisualComponent
          data={topSchools}
          mode={mode}
          title="Top Performing Schools"
          iconSrc="/images/trophy.png"
          tableHeaders={["#", "Schools", "Points"]}
        />
      )}
    </div>
  );
}
