import ArticleCarousalCardSkeleton from "@/components/cards/ArticleCarousalCardSkeleton";
import HorizontalArticleCardSkeleton from "@/components/cards/HorizontalArticleCardSkeleton";

export default function SubCategoryWiseNewsSkeleton() {
  return (
    <div className="mt-8 grid grid-cols-12 gap-8">
      <div className="col-span-4">
        <ArticleCarousalCardSkeleton />
      </div>
      <div className="col-span-8 grid grid-cols-2 gap-x-8 gap-y-5">
        {Array(8)
          .fill(0)
          ?.map((_, index) => {
            return (
              <div key={index}>
                <HorizontalArticleCardSkeleton showRegion />

                <div className="border-b pt-3"></div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
