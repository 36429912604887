export const QUERY_VISUAL_STORIES = ({ categoryId }: { categoryId?: string }) => `
  query VisualStories($limit: Int, $page: Int, $sort: String) {
    VisualStories(
      where: { 
        status: { equals: published } 
        ${categoryId ? `categoryId: { equals: "${categoryId}" }` : ""}  
      }, 
      sort: $sort, 
      limit: $limit, 
      page: $page
    ) {
      docs {
        id
        title
        slug
        content
        thumbnail {
          alt
          url
          height
          width
          sizes {
            hd {
              url
            }
            sd {
              url
            }
          }
        }
      }
      totalDocs
    }
  }
`;

export const QUERY_SINGLE_VISUAL_STORY = `
  query VisualStory($slug: String) {
    VisualStories(where: { slug: { equals: $slug } }) {
      docs {
        id
        title
        slug
        content
        thumbnail {
          alt
          url
        }
        mediaItems {
          id
          media {
            alt
            url
          }
          title
          caption
        }
        createdAt
        meta {
          title
          description
          keywords
          image {
            url
          }
        }
      }
    }
  }

`;

export const QUERY_NEXT_VISUAL_STORY = `
  query VisualStory($currentId: String!, $createdAt: DateTime!) {
    VisualStories(
      where: {
        id: { not_equals: $currentId }
        status: { equals: published }
        createdAt: { less_than: $createdAt }
      }
      limit: 1
    ) {
      docs {
        id
        title
        slug
        thumbnail {
          alt
          url
          filename
        }
        createdAt
      }
    }
  }
`;

export const QUERY_TOTAL_VISUALSTORIES_COUNT = `
  query getVisualStoriesCount {
    VisualStories(where: { status: { equals: published } }) {
      totalDocs
    }
  }
`;

export const QUERY_VISUALSTORIES_SLUG = `
  query getVisualStoriesSlug($page: Int, $limit: Int) {
    VisualStories(
      where: { status: { equals: published } }
      page: $page
      limit: $limit
    ) {
      docs {
        slug
        updatedAt
      }
    }
  }
`;
