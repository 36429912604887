"use client";

import React, { useRef, useState } from "react";
import Slider from "react-slick";
import { MdOutlineRemove } from "react-icons/md";

import { ThreeCardBlockType } from "@/types/page";
import { ImageCardSection } from "./ImageCardSection";
import CustomImage from "@/components/shared/CustomImage";
import { getImageUrl } from "@/utils/getImageUrl";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function ThreeCardBlock({ block }: { block: ThreeCardBlockType }) {
  let sliderRef = useRef<Slider | null>(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const numOfImageCards = block?.imageCards?.length;

  const settings = {
    dots: true,
    speed: 500,
    infinite: numOfImageCards > 1,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: numOfImageCards > 1,
    beforeChange: (oldIndex: number, newIndex: number) => {
      setActiveIndex(newIndex);
    },
    customPaging: (i: number) => {
      return (
        <div
          style={{
            position: "absolute",
            bottom: 20,
            width: "50px",
          }}
        >
          <MdOutlineRemove size={30} color={i === activeIndex ? "red" : "white"} />
        </div>
      );
    },
  };

  return (
    <div className="relative grid grid-cols-1 gap-5 md:grid-cols-10 md:gap-8">
      <div className="image_card_section_1 md:col-span-3 h-[180px] md:h-[220px] w-full">
        <Slider className="relative h-full" {...settings} ref={sliderRef} lazyLoad="ondemand">
          {block?.imageCards?.length &&
            block?.imageCards?.map((card, index) => {
              return <ImageCardSection data={card} key={index} />;
            })}
        </Slider>
      </div>
      <div className="logo_card_section md:col-span-3 w-full h-[180px] md:h-[220px] rounded-3xl overflow-hidden shadow">
        <a
          href={block?.logoCard?.url ?? "#"}
          aria-label={`Visit page: ${block?.logoCard?.url}`}
          className="w-full h-full"
        >
          <CustomImage
            src={getImageUrl(block?.logoCard?.logoImage?.sizes?.md?.filename || "")}
            alt={block?.logoCard?.url}
            className="object-cover w-full h-full"
          />
        </a>
      </div>
      <div className="photo_story_card_section md:col-span-4 relative w-full h-[180px] md:h-[220px] rounded-3xl overflow-hidden shadow">
        <a
          href={block?.photoStoryCard?.url ?? "#"}
          aria-label={`Visit photo story page: ${block?.photoStoryCard?.url}`}
          className="w-full h-full"
        >
          <CustomImage
            src={getImageUrl(block?.photoStoryCard?.bgImage?.sizes?.md?.filename || "")}
            alt={block?.photoStoryCard?.url}
            className="object-cover w-full h-full"
          />
          <div className="absolute inset-0 z-10 bg-black/20"></div>
          <div className="absolute z-20 bottom-5 left-5">
            <p className="text-base font-bold text-white uppercase">
              Photo <span className="text-primaryRed">Stories</span>
            </p>
          </div>
        </a>
      </div>
    </div>
  );
}
