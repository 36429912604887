"use client";

import { useCallback, useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { BsThreeDots } from "react-icons/bs";
import { useHandleClickOutside } from "@/hooks";

import SubCategoryWiseNewsSkeleton from "./SubCategoryWiseNewsSkeleton";
import ArticleCarousalCard from "@/components/cards/ArticleCarousalCard";
import HorizontalArticleCard from "@/components/cards/HorizontalArticleCard";
import { fetchConditionalNewsAPI, fetchNews } from "@/services/newsService";
import { ArticleListType, ArticleSubCategoryType } from "@/types/article";

interface SubCategoryWiseNewsProps {
  subCategories: ArticleSubCategoryType[];
  orderBy?: "latest" | "oldest" | "trending" | "views";
}

export const SubCategoryWiseNews = ({ subCategories, orderBy }: SubCategoryWiseNewsProps) => {
  const [selectedSubCategory, setSelectedSubCategory] = useState<string>(
    subCategories[0]?.id || "",
  );
  const [visibleCount, setVisibleCount] = useState(0);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const hiddenItemRef = useRef<HTMLDivElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  useHandleClickOutside(dropdownRef, () => setDropdownOpen(false));
  const sectionRef = useRef(null);

  const block = {
    id: selectedSubCategory,
    blockType: "subCategoryWiseNewsBlock",
    subCategory: selectedSubCategory,
    orderBy,
    limit: 12,
  };

  const { data, error, isFetching } = useQuery<ArticleListType[]>({
    queryKey: ["fetchNewsBySubCategory", selectedSubCategory],
    queryFn: () => fetchConditionalNewsAPI({ block, pageSlug: "default", limit: block?.limit || 4 }),
    enabled: !!selectedSubCategory,
  });

  const calculateVisibleItems = useCallback(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.getBoundingClientRect().width;
      let totalWidth = 0;
      let count = 0;
      const gap = 16;
      const ellipsisSpace = 50;

      for (let i = 0; i < subCategories.length; i++) {
        // const item = containerRef.current.children[i];
        const item = hiddenItemRef.current;

        if (!item) return;

        item.textContent = subCategories[i]?.name;
        const itemWidth = item?.getBoundingClientRect()?.width;

        if (i === 0) {
          totalWidth += itemWidth;
          count++;
        } else if (totalWidth + itemWidth + gap + ellipsisSpace <= containerWidth) {
          totalWidth += itemWidth + gap;
          count++;
        } else {
          break;
        }
      }

      setVisibleCount(count);
    }
  }, [subCategories]);

  useEffect(() => {
    const handleResize = () => calculateVisibleItems();
    calculateVisibleItems();

    window.addEventListener("resize", handleResize);

    return () => {
      return window.addEventListener("resize", handleResize);
    };
  }, [calculateVisibleItems]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target); // Once it's visible, stop observing
        }
      },
      {
        threshold: [0.1],
        rootMargin: "500px 0px 500px 0px",
      },
    );

    const currentSection = sectionRef?.current;

    if (currentSection) {
      observer.observe(currentSection);
    }

    return () => {
      if (currentSection) {
        observer.unobserve(currentSection);
      }
    };
  }, []);

  if (error) {
    return <div>Something went wrong!</div>;
  }

  return (
    <div ref={sectionRef}>
      <div ref={containerRef} className="flex items-center gap-8 py-3 mt-8 border-y">
        <div className="flex items-center gap-4">
          {subCategories?.slice(0, visibleCount)?.map((subCategory, index) => {
            return (
              <div
                key={index}
                onClick={() => setSelectedSubCategory(subCategory?.id)}
                className={
                  "cursor-pointer transition-all hover:font-medium px-2 dark:text-white " +
                  (selectedSubCategory === subCategory?.id ? " font-semibold " : " font-normal ")
                }
              >
                <span className="text-sm whitespace-nowrap font-anek">{subCategory?.name}</span>
              </div>
            );
          })}
        </div>

        {!isFetching && visibleCount < subCategories?.length && (
          <div className="relative">
            <button
              onClick={() => setDropdownOpen(true)}
              className="flex items-center justify-center dark:text-white"
              aria-label="More Categories"
            >
              <BsThreeDots />
            </button>

            {isDropdownOpen && (
              <div
                ref={dropdownRef}
                className="absolute right-0 top-[30px] bg-white dark:bg-darkBgSecondaryColor dark:text-white w-[210px] shadow-md rounded z-10"
              >
                {subCategories?.slice(visibleCount)?.map((subCategory, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        setSelectedSubCategory(subCategory?.id);
                        setDropdownOpen(false);
                      }}
                      className={
                        "cursor-pointer transition-all hover:font-medium px-3 py-[5px] border-b " +
                        (selectedSubCategory === subCategory?.id ? "font-medium" : "font-normal")
                      }
                    >
                      <span className="text-sm">{subCategory?.name}</span>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </div>

      <span ref={hiddenItemRef} className="absolute invisible px-2 whitespace-nowrap"></span>

      {!isVisible || isFetching ? (
        <SubCategoryWiseNewsSkeleton />
      ) : (
        <div className="grid grid-cols-12 gap-8 mt-8">
          <div className="col-span-12 md:col-span-4">
            <ArticleCarousalCard articles={data?.slice(0, 4) || []} />
          </div>
          <div className="grid col-span-12 md:col-span-8 md:grid-cols-2 gap-x-8 gap-y-5">
            {data?.slice(4, 12)?.map((article, index) => {
              return (
                <div key={index}>
                  <HorizontalArticleCard article={article || undefined} index={index} />

                  <div className="pt-3 border-b"></div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
