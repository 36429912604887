import envConfig from "@/config/envConfig";
import { QUERY_PODCASTS_SLUG, QUERY_TOTAL_PODCASTS_COUNT } from "@/graphql/podcasts";
import { PodcastType } from "@/types/podcasts";
import { notFound } from "next/navigation";
import { stringify } from "qs-esm";

export const fetchPodcast = async ({
  limit,
  page,
  categoryId,
}: {
  limit: number;
  page: number;
  categoryId?: string;
}) => {
  try {
    const response = await fetch(
      `${envConfig.publicServerUrl}/api/podcasts?limit=${limit}&page=${page}&sort=-createdAt${
        categoryId ? `&where[or][0][and][0][categoryId][equals]=${categoryId}` : ""
      }`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        cache: "no-cache",
      },
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    return await response.json();
  } catch (err) {
    console.error("Error in fetchSearchResult:", err);
    return err;
  }
};

export const fetchPodcastBySlug = async ({
  slug,
  cache = "no-cache",
}: {
  slug: string;
  cache?: RequestCache;
}) => {
  const query = {
    slug: {
      equals: slug,
    },
  };

  const stringifiedQuery = stringify(
    {
      where: query,
      limit: 1,
    },
    { addQueryPrefix: true },
  );

  try {
    const response = await fetch(envConfig.publicServerUrl + `/api/podcasts${stringifiedQuery}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      cache,
    });

    if (!response.ok) {
      throw Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();

    return (respData?.docs[0] || null) as PodcastType;
  } catch (err) {
    return notFound();
  }
};

export const fetchTotalPodcastsCount = async () => {
  try {
    const response = await fetch(envConfig.publicQraphqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: QUERY_TOTAL_PODCASTS_COUNT,
      }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();
    return (respData?.data?.Podcasts?.totalDocs || 0) as number;
  } catch (err) {
    console.log(err);
  }
};

export const fetchAllPodcastsSlug = async ({
  page = 1,
  limit = 10,
}: {
  page?: number;
  limit?: number;
}) => {
  try {
    const response = await fetch(envConfig.publicQraphqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: QUERY_PODCASTS_SLUG,
        variables: {
          page,
          limit,
        },
      }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();
    return (respData?.data?.Podcasts?.docs || []) as PodcastType[];
  } catch (err) {
    console.log(err);
  }
};
