export const QUERY_PHOTO_STORIES = ({
  categoryId,
  featured,
}: {
  categoryId?: string;
  featured?: string;
}) => `
  query PhotoStories($limit: Int, $page: Int, $sort: String) {
    PhotoStories(
      where: { 
        status: { equals: published }
        ${categoryId ? `categoryId: { equals: "${categoryId}" }` : ""}
        ${featured ? `id: { not_in: ["${featured}"] }` : ""}
      }
      sort: $sort
      limit: $limit
      page: $page
    ) {
      docs {
        id
        title
        slug
        content
        thumbnail {
          alt
          url
          sizes {
            hd {
              url
            }
            sd {
              url
            }
          }
        }
        createdAt
      }
      totalDocs
    }
  }
`;

export const QUERY_SINGLE_PHOTO_STORY = `
  query PhotoStories($slug: String) {
    PhotoStories(
      where: { slug: { equals: $slug } }, 
    ) {
      docs {
        id
        title
        slug
        content
        thumbnail {
          alt
          url
        }
        mediaItems {
          id
          media {
            alt
            url
          }
          caption
          showPhotographerInfo
          photographer {
            id
            fullName
          }
        }
        createdAt
      }
    }
  }
`;

export const QUERY_TOTAL_PHOTOSTORIES_COUNT = `
  query getPhotoStoriesCount {
    PhotoStories(where: { status: { equals: published } }) {
      totalDocs
    }
  }
`;

export const QUERY_PHOTOSTORIES_SLUG = `
  query getPhotoStoriesSlug($page: Int, $limit: Int) {
    PhotoStories(
      where: { status: { equals: published } }
      page: $page
      limit: $limit
    ) {
      docs {
        slug
        updatedAt
      }
    }
  }
`;
