"use client";

import { useEffect, useState } from "react";
import Link from "next/link";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import { IoMdShare } from "react-icons/io";

import CustomImage from "@/components/shared/CustomImage";
import { Typography } from "@/components/shared/Typography";
import { fetchPaginatedNews } from "@/services/newsService";
import { ArticleType } from "@/types/article";
import { getNewsDetailsUrl } from "@/utils/getNewsDetailsUrl";
import { getImageUrl } from "@/utils/getImageUrl";
import SocialShare from "@/components/shared/SocialShare";
import { PaginatedNewsListBlockType } from "@/types/page";
import TitleTag from "@/components/shared/TitleTag";

interface PaginatedNewsListProps {
  initialData: {
    docs: ArticleType[];
    totalDocs: number;
  };
  block: PaginatedNewsListBlockType;
}

export default function PaginatedNewsList({ initialData, block }: PaginatedNewsListProps) {
  const [articles, setArticles] = useState<ArticleType[]>(initialData.docs || []);
  const [filters, setFilters] = useState({
    limit: block?.limit,
    page: 1,
  });

  const sort =
    block?.orderBy === "trending"
      ? "-trendingPosition"
      : block?.orderBy === "views"
      ? "-views"
      : block?.orderBy === "oldest"
      ? "publishedAt"
      : "-publishedAt";

  const { data, error, isLoading, isFetching } = useQuery({
    queryKey: ["fetchNewsByTag", filters.page],
    enabled: filters.page > 1,
    queryFn: () =>
      fetchPaginatedNews({
        categoryId: block?.category,
        subCategoryId: block?.subCategory,
        limit: block?.limit,
        sort,
        ...(block?.orderBy === "trending" && { isTrending: true }),
        page: filters?.page,
      }),
  });

  const handleLoadMore = () => {
    setFilters(prev => ({ ...prev, page: prev.page + 1 }));
  };

  useEffect(() => {
    if (data) {
      setArticles(prev => [...prev, ...(data?.data?.docs || [])]);
    }
  }, [data]);

  // Determine if we should show the Load More button
  const showLoadMore =
    articles?.length > 0 &&
    ((filters.page === 1 && initialData.totalDocs > articles.length) ||
      (data && data?.data?.totalDocs > articles.length));

  return (
    <div>
      {articles?.map(
        (
          { image, title, slug, categoryId, subCategoryId, publishedAt, isLiveNews, isInPrint },
          index,
        ) => {
          return (
            <div
              key={index}
              className="flex flex-row items-start gap-6 pt-6 pb-6 border-b group first:pt-0"
            >
              <Link
                href={getNewsDetailsUrl(categoryId, subCategoryId, slug)}
                className="w-[30%] min-w-[30%] md:w-[33%] md:min-w-[33%] overflow-hidden rounded-md aspect-[16/12] md:aspect-video"
                aria-label={`View news: ${title}`}
              >
                <CustomImage
                  src={getImageUrl(image?.sizes?.ld?.filename || image?.filename)}
                  alt={image?.alt || ""}
                  className="object-cover w-full h-full img-zoom-hover"
                />
              </Link>
              <div className="w-full">
                <Link
                  href={getNewsDetailsUrl(categoryId, subCategoryId, slug)}
                  aria-label={`View news: ${title}`}
                >
                  <Typography
                    variant="h5"
                    language="ml"
                    className="line-clamp-3 md:line-clamp-2 font-semibold !text-base md:!text-[18px]"
                  >
                    {isLiveNews && <TitleTag content="Live News" />}
                    {isInPrint && <TitleTag content="print edition" />}
                    {title}
                  </Typography>
                </Link>
                <div className="hidden gap-4 mt-4 uppercase divide-x-2 divide-black md:flex dark:divide-white">
                  <Link
                    href={`/${categoryId?.slug}`}
                    aria-label={`View all news in the category: ${categoryId?.name}`}
                  >
                    <Typography className="font-medium" variant="body2">
                      {categoryId?.name}
                    </Typography>
                  </Link>
                  <Link
                    href={`/${categoryId?.slug}/${subCategoryId?.slug}`}
                    aria-label={`View all news in the sub-category: ${subCategoryId?.name}`}
                  >
                    <Typography
                      className="pl-4 text-secondaryText dark:text-darkBgSecondaryColor"
                      variant="body2"
                    >
                      {subCategoryId?.name}
                    </Typography>
                  </Link>
                </div>

                <div className="flex items-center justify-between gap-4 mt-4">
                  <span className="block text-[#858585] text-sm uppercase">
                    {moment(publishedAt)?.format("MMMM DD, YYYY")}
                  </span>

                  <div className="hidden md:block">
                    <SocialShare
                      title={title}
                      url={getNewsDetailsUrl(categoryId, subCategoryId, slug)}
                    />
                  </div>
                  <div className="md:hidden">
                    <SocialShare
                      title={title}
                      url={getNewsDetailsUrl(categoryId, subCategoryId, slug)}
                      customButton={
                        <button className="flex items-center justify-center" aria-label="Share">
                          <IoMdShare />
                        </button>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        },
      )}

      {showLoadMore && (
        <div className="flex items-center justify-center mt-8">
          <button
            className="load-more-btn"
            onClick={handleLoadMore}
            disabled={isFetching}
            aria-label="Load More"
          >
            Load More
          </button>
        </div>
      )}
    </div>
  );
}
