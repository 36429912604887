"use client";

import React, { useRef } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function SliderComponent({
  sliderItemCount,
  children,
}: {
  sliderItemCount: number;
  children: React.ReactNode;
}) {
  let sliderRef = useRef<Slider | null>(null);

  const settings = {
    dots: false,
    speed: 500,
    infinite: sliderItemCount > 3,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          infinite: sliderItemCount > 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          infinite: sliderItemCount > 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          infinite: sliderItemCount > 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="mt-8">
      <Slider {...settings} ref={sliderRef} lazyLoad="ondemand">
        {children}
      </Slider>
    </div>
  );
}
