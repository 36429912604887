"use client";

import moment from "moment";
import Slider, { Settings } from "react-slick";
import Link from "next/link";

import { Typography } from "@/components/shared/Typography";
import CustomImage from "../shared/CustomImage";
import { getNewsDetailsUrl } from "@/utils/getNewsDetailsUrl";
import { ArticleListType } from "@/types/article";
import { getImageUrl } from "@/utils/getImageUrl";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import TitleTag from "../shared/TitleTag";

export type ArticleCarousalCardProps = {
  articles: ArticleListType[];
};

export default function ArticleCarousalCard({ articles }: ArticleCarousalCardProps) {
  const settings: Settings = {
    dots: true,
    infinite: articles?.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
  };

  // TODO: Handle this later
  if (articles?.length < 1) {
    return <></>;
  }

  return (
    <Slider {...settings}>
      {articles?.map(
        (
          {
            title,
            slug,
            image,
            summary,
            publishedAt,
            categoryId,
            subCategoryId,
            focusPosition,
            isLiveNews,
            isInPrint,
          },
          index,
        ) => {
          return (
            <article key={index} className="w-full group">
              {/* Title */}
              <Link
                href={getNewsDetailsUrl(categoryId, subCategoryId, slug)}
                aria-label={`View news: ${title}`}
                prefetch={true}
              >
                <Typography language="ml" variant="h3" className="line-clamp-3">
                  {isLiveNews && <TitleTag content="Live News" />}
                  {isInPrint && <TitleTag content="print edition" />}
                  {title}
                </Typography>
              </Link>

              {/* Thumbnail */}
              <Link
                href={getNewsDetailsUrl(categoryId, subCategoryId, slug)}
                aria-label={`View news: ${title}`}
              >
                <div className="w-full mt-4 overflow-hidden rounded-lg aspect-video">
                  <CustomImage
                    src={getImageUrl(image?.sizes?.sd?.filename || image?.filename)}
                    alt={image?.alt || ""}
                    className={`object-cover object-${focusPosition} w-full h-full img-zoom-hover`}
                    phImg="/images/placeholder-sd.png"
                    loading="eager"
                  />
                </div>
              </Link>

              {/* Category */}
              <div className="flex gap-4 mt-3 uppercase divide-x-2 divide-black dark:divide-white">
                <Link
                  href={`/${categoryId?.slug}`}
                  aria-label={`View all news in the category: ${categoryId?.name}`}
                >
                  <Typography className="font-medium" variant="body2">
                    {categoryId?.name}
                  </Typography>
                </Link>
                <Link
                  href={`/${categoryId?.slug}/${subCategoryId?.slug}`}
                  aria-label={`View all news in the sub-category: ${subCategoryId?.name}`}
                >
                  <Typography
                    className="pl-4 text-secondaryText dark:text-darkBgSecondaryColor"
                    variant="body2"
                  >
                    {subCategoryId?.name}
                  </Typography>
                </Link>
              </div>

              {/* Content */}
              <Link
                href={getNewsDetailsUrl(categoryId, subCategoryId, slug)}
                aria-label={`View news: ${title}`}
              >
                <Typography className="mt-4 line-clamp-3" language="ml">
                  {summary}
                </Typography>
              </Link>

              {/* Time */}
              <p className="mt-3 text-primaryRed font-anek">{moment(publishedAt).fromNow()}</p>
            </article>
          );
        },
      )}
    </Slider>
  );
}
