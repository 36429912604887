"use client";

import { useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import SectionHeader from "@/components/shared/SectionHeader";
import { fetchQuotes } from "@/services/quotesService";
import QuotesList from "./QuotesList";
import { ArticlesAndPeriodicalsBlockType } from "@/types/page";
import SimpleLoader from "@/components/shared/SimpleLoader";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function ArticlesAndPeriodicalsBlock({
  block,
}: {
  block: ArticlesAndPeriodicalsBlockType;
}) {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  const { data, error, isFetching } = useQuery({
    queryKey: ["fetchQuotes"],
    queryFn: () =>
      fetchQuotes({
        limit: block?.limit,
      }),
    refetchOnWindowFocus: false,
    enabled: isVisible,
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target); // Once it's visible, stop observing
        }
      },
      {
        threshold: [0.1],
        rootMargin: "500px 0px 500px 0px",
      },
    );

    const currentSection = sectionRef?.current;

    if (currentSection) {
      observer.observe(currentSection);
    }

    return () => {
      if (currentSection) {
        observer.unobserve(currentSection);
      }
    };
  }, []);

  if (!data || !isVisible || isFetching) {
    return (
      <section ref={sectionRef} className="h-[100px]">
        <SimpleLoader />
      </section>
    );
  }

  return (
    <section ref={sectionRef} style={{ backgroundColor: block?.backgroundColor }} className="py-8 ">
      <div className="container">
        <div className="flex justify-center">
          <SectionHeader title={block?.title} titleColor="#fff" link="/articles-periodicals" />
        </div>

        <QuotesList quotes={data || []} />
      </div>
    </section>
  );
}
