// export const SHORTS = `
//   query Shorts($limit: Int, $page: Int, $sort: String) {
//     Shorts(where: { status: { equals: published } }, sort: $sort, limit: $limit, page: $page) {
//       docs {
//         id
//         title
//         slug
//         thumbnail {
//           alt
//           url
//         }
//         video {
//           url
//           hlsPlaybackUrl
//         }
//         likes
//       }
//     }
//   }
// `;

export const SHORTS = ({
  categoryId,
  subCategoryId,
}: {
  categoryId?: string;
  subCategoryId?: string;
}) => {
  return `
  query Shorts($limit: Int, $page: Int, $sort: String) {
    Shorts(
      where: { 
        status: { equals: published },
        ${categoryId ? `category: { equals: "${categoryId}" }` : ""}
        ${subCategoryId ? `subCategory: { equals: "${subCategoryId}" }` : ""}
      }, 
      sort: $sort, 
      limit: $limit, 
      page: $page
    ) {
      docs {
        id
        title
        slug
        thumbnail {
          alt
          url
          sizes {
            hd {
              url
            }
            sd {
              url
            }
          }
        }
        video {
          url
          hlsPlaybackUrl
        }
        likes
      }
    }
  }
`;
};

export const SHORTS_BY_SLUG = `
  query Shorts($slug: String!) {
    Shorts(where: { slug: { equals: $slug } }) {
      docs {
        id
        title
        slug
        thumbnail {
          alt
          url
          height
          width
        }
        video {
          url
          hlsPlaybackUrl
        }
        likes
        meta {
          title
          description
          keywords
          image {
            url
          }
        }
      }
    }
  }
`;

export const QUERY_TOTAL_SHORTS_COUNT = `
  query getShortsCount {
    Shorts(where: { status: { equals: published } }) {
      totalDocs
    }
  }
`;

export const QUERY_SHORTS_SLUG = `
  query getShortsSlug($page: Int, $limit: Int) {
    Shorts(where: { status: { equals: published } }, page: $page, limit: $limit) {
      docs {
        slug
        updatedAt
      }
    }
  }
`;
