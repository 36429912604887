"use client";

import { LiaSquareFullSolid } from "react-icons/lia";

export default function TitleNewsBlockSkeleton({ limit }: { limit: number }) {
  return (
    <div className="flex overflow-x-scroll no-scrollbar md:grid grid-cols-4 gap-12">
      {Array(limit)
        .fill(0)
        .map((_, index) => {
          return (
            <div key={index}>
              <div className="flex items-center w-full min-w-60 md:min-w-max gap-3 mb-[27px]">
                <span className="block h-[12px] w-[120px] bg-slate-300 animate-pulse"></span>
                <span className="text-primaryRed flex items-center w-full">
                  <LiaSquareFullSolid size={10} />
                  <span className="border-t border-dashed w-full border-primaryRed"></span>
                </span>
              </div>
              <div className="flex flex-col gap-[12px]">
                <span className="block h-[17px] w-[100%] bg-slate-300 animate-pulse"></span>
                <span className="block h-[17px] w-[100%] bg-slate-300 animate-pulse"></span>
                <span className="block h-[17px] w-[65%] bg-slate-300 animate-pulse"></span>
              </div>
            </div>
          );
        })}
    </div>
  );
}
