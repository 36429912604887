import React, { FC } from "react";

import { ThreeCardImageCardTypes } from "@/types/page";
import CustomImage from "@/components/shared/CustomImage";
import { Typography } from "@/components/shared/Typography";
import { getImageUrl } from "@/utils/getImageUrl";

interface ImageCardSectionProps {
  data: ThreeCardImageCardTypes;
}

export const ImageCardSection: FC<ImageCardSectionProps> = ({ data }) => {
  return (
    <div className="relative w-full h-[180px] md:h-[220px] rounded-3xl overflow-hidden shadow">
      <CustomImage
        src={getImageUrl(data?.image?.sizes?.md?.filename || "")}
        alt={data?.title}
        className="object-cover w-full h-full "
      />
      <div className="absolute inset-0 z-10 bg-black/20"></div>
      <div className="absolute z-20 bottom-8 inset-x-3">
        <Typography variant="h5" color="#fff" language="ml" className="line-clamp-2">
          {data?.title}
        </Typography>
      </div>
    </div>
  );
};
