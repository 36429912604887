export const QUERY_QUOTES = `
  query getQuotes($limit: Int = 5, $sort: String) {
    Quotes(where: { status: { equals: published } }, limit: $limit, sort: $sort) {
      docs {
        author
        id
        image {
          alt
          url
        }
        position
        quote
        updatedAt
        slug
      }
    }
  }
`;

export const QUERY_TOTAL_QUOTES_COUNT = `
  query getQuotesCount {
    Quotes(where: { status: { equals: published } }) {
      totalDocs
    }
  }
`;

export const QUERY_QUOTES_SLUG = `
  query getQuotesSlug($page: Int, $limit: Int) {
    Quotes(where: { status: { equals: published } }, page: $page, limit: $limit) {
      docs {
        slug
        updatedAt
      }
    }
  }
`;
