"use client";

import { useEffect, useRef, useState } from "react";
import Slider, { Settings } from "react-slick";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

import QuoteCard from "@/components/cards/QuoteCard";
import { QuotesType } from "@/types/quotes";

interface QuotesListProps {
  quotes: QuotesType[];
}

export default function QuotesList({ quotes }: QuotesListProps) {
  const [slideIndex, setSlideIndex] = useState<number>(0);
  const [showArrows, setShowArrows] = useState<boolean>(false);

  let sliderRef = useRef<Slider | null>(null);

  const settings: Settings = {
    dots: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: quotes?.length > 4,
    arrows: false,
    autoplay: true,
    beforeChange: (_: number, next: number) => setSlideIndex(next),
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: quotes?.length > 4,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: quotes?.length > 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: quotes?.length > 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: quotes?.length > 1,
        },
      },
    ],
  };

  const updateVisibility = () => {
    const width = window.innerWidth;
    const config = settings.responsive!.find(c => width <= c.breakpoint);
    if (config) {
      // @ts-ignore
      setShowArrows(true);
    } else {
      setShowArrows(false);
    }
  };

  useEffect(() => {
    updateVisibility();
    window.addEventListener("resize", updateVisibility);
    return () => window.removeEventListener("resize", updateVisibility);
  }, []);

  return (
    <div className="mt-8">
      <Slider
        {...settings}
        ref={slider => {
          if (slider) {
            sliderRef.current = slider;
          }
        }}
      >
        {quotes?.map((quote, index) => {
          return (
            <div key={index} className="pr-8">
              <QuoteCard {...quote} />
            </div>
          );
        })}
      </Slider>

      {showArrows ? (
        <div className="flex items-center justify-center gap-3 mt-5">
          <CustomArrow pos="left" onClick={() => sliderRef?.current?.slickGoTo(slideIndex - 1)} />
          {quotes?.map((_, index) => {
            return (
              <span
                key={index}
                className={
                  "block size-2 rounded-full cursor-pointer transition-all hover:bg-white " +
                  (index === slideIndex ? "bg-white" : "bg-[#A4A4A480]")
                }
                onClick={() => sliderRef?.current?.slickGoTo(index)}
              ></span>
            );
          })}
          <CustomArrow pos="right" onClick={() => sliderRef?.current?.slickGoTo(slideIndex + 1)} />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

const CustomArrow = ({ pos, onClick }: { pos: "left" | "right"; onClick: () => void }) => {
  return (
    <div
      className="size-6 bg-white flex items-center justify-center rounded-full cursor-pointer"
      onClick={onClick}
    >
      {pos === "left" ? <FaChevronLeft size={14} /> : <FaChevronRight size={14} />}
    </div>
  );
};
