import { IoPlay } from "react-icons/io5";

import CustomImage from "../shared/CustomImage";
import { ShortType } from "@/types/shorts";
import { Typography } from "../shared/Typography";
import { getImageUrl } from "@/utils/getImageUrl";

interface ShortsCardProps {
  short: ShortType;
}

export default function ShortsCard({ short }: ShortsCardProps) {
  const { slug, thumbnail, title } = short;

  return (
    <a
      href={`/shorts/${slug}`}
      className="relative w-full aspect-[9/16] rounded-[14.538px] overflow-hidden"
      target="_blank"
      aria-label={`View short: ${title}`}
    >
      <div className="relative w-full h-full ">
        <CustomImage
          src={getImageUrl(thumbnail?.url)}
          alt={thumbnail?.alt || ""}
          className="object-cover w-full h-full"
          phImg="/images/placeholder-md.png"
        />
      </div>
      <div className="absolute top-0 left-0 w-full h-full rounded-md bg-gradient-to-b from-transparent to-black"></div>
      <div className="absolute top-4 right-4 size-9 rounded-full flex justify-center items-center bg-[#FF006B] text-white pl-1">
        <IoPlay size={20} />
      </div>
      <div className="absolute top-0 left-0 right-0 flex flex-col justify-end h-full px-4 py-8">
        <Typography language="ml" variant="h6" color="#fff" className="line-clamp-2">
          {title}
        </Typography>
      </div>
    </a>
  );
}
