"use client";

import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";

import SectionHeader from "@/components/shared/SectionHeader";
import ShortsCard from "../cards/ShortsCard";
import { ShortsBlockType } from "@/types/page";
import { fetchShorts } from "@/services/shortsService";
import SimpleLoader from "../shared/SimpleLoader";

export default function ShortsBlock({ block }: { block: ShortsBlockType }) {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  const { data, error, isFetching } = useQuery({
    queryKey: ["fetchShorts"],
    queryFn: () =>
      fetchShorts({
        categoryId: block?.category ?? "",
        subCategoryId: block?.subCategory ?? "",
        limit: 6,
      }),
    refetchOnWindowFocus: false,
    enabled: isVisible,
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target); // Once it's visible, stop observing
        }
      },
      {
        threshold: [0.1],
        rootMargin: "500px 0px 500px 0px",
      },
    );

    const currentSection = sectionRef.current;

    if (currentSection) {
      observer.observe(currentSection);
    }

    return () => {
      if (currentSection) {
        observer.unobserve(currentSection);
      }
    };
  }, []);

  if (!isVisible || isFetching) {
    return (
      <section ref={sectionRef} className="h-[100px]">
        <SimpleLoader />
      </section>
    );
  }

  return (
    <section className="" ref={sectionRef}>
      <SectionHeader title={block?.title} link="/videos/shorts" />
      <div className="hidden md:grid grid-cols-2 gap-4 mt-8 md:grid-cols-5 no-scrollbar">
        {data?.docs?.slice(0,5)?.map((short, index) => {
          return <ShortsCard key={index} short={short} />;
        })}
      </div>
      <div className="md:hidden grid grid-cols-2 gap-4 mt-8 md:grid-cols-5 no-scrollbar">
        {data?.docs?.map((short, index) => {
          return <ShortsCard key={index} short={short} />;
        })}
      </div>
    </section>
  );
}
