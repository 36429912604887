"use client";

import { useRef, useState } from "react";
import Slider from "react-slick";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Link from "next/link";

import Avatar from "@/components/shared/Avatar";
import { Typography } from "@/components/shared/Typography";
import { InterviewType } from "@/types/interviews";
import { getImageUrl } from "@/utils/getImageUrl";

interface InterviewsListProps {
  interviews: InterviewType[];
}

export default function InterviewsList({ interviews }: InterviewsListProps) {
  const [slideIndex, setSlideIndex] = useState<number>(0);

  let sliderRef = useRef<Slider | null>(null);

  const settings = {
    dots: false,
    infinite: interviews?.length > 5,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    beforeChange: (_: number, next: number) => setSlideIndex(next),
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
          infinite: interviews?.length > 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          infinite: interviews?.length > 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          infinite: interviews?.length > 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          infinite: interviews?.length > 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="px-10 mt-20">
      <Slider
        {...settings}
        ref={slider => {
          if (slider) {
            sliderRef.current = slider;
          }
        }}
      >
        {interviews?.slice(1)?.map(({ image, interviewee, role, summary, title, slug }, index) => (
          <div key={index} className="relative pr-8">
            <Link href={`/interviews/${slug}`} aria-label={`View interview: ${title}`}>
              <div className="min-h-[300px] bg-white dark:bg-darkBgPrimaryColor rounded-xl overflow-hidden p-7 flex flex-col gap-5">
                <div className="flex gap-5">
                  <Avatar
                    image={getImageUrl(image?.sizes?.ld?.url || image?.url)}
                    showQuoteMark
                    width={56}
                    height={56}
                  />
                  <div className="flex flex-col">
                    <Typography variant="h4">{interviewee}</Typography>
                    <Typography variant="body2">{role}</Typography>
                  </div>
                </div>
                <hr />
                <div className="flex flex-col gap-2">
                  <Typography variant="h4" language="ml" className="line-clamp-2">
                    {title}
                  </Typography>
                  <Typography variant="body2" language="ml" className="line-clamp-3">
                    {summary}
                  </Typography>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </Slider>

      <div className="flex items-center justify-center gap-3 mt-10">
        <CustomArrow pos="left" onClick={() => sliderRef?.current?.slickGoTo(slideIndex - 1)} />
        {interviews?.slice(1)?.map((_, index) => {
          return (
            <span
              key={index}
              className={
                "block size-2 rounded-full cursor-pointer transition-all hover:bg-white " +
                (index === slideIndex ? "bg-white" : "bg-[#A4A4A480]")
              }
              onClick={() => sliderRef?.current?.slickGoTo(index)}
            ></span>
          );
        })}
        <CustomArrow pos="right" onClick={() => sliderRef?.current?.slickGoTo(slideIndex + 1)} />
      </div>
    </div>
  );
}

const CustomArrow = ({ pos, onClick }: { pos: "left" | "right"; onClick: () => void }) => {
  return (
    <div
      className="size-6 bg-white flex items-center justify-center rounded-full cursor-pointer"
      onClick={onClick}
    >
      {pos === "left" ? <FaChevronLeft size={14} /> : <FaChevronRight size={14} />}
    </div>
  );
};
